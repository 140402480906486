import React from "react"
import * as S from "./styled"

import NoInstallIcon from "../../icons/noInstallIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const NoInstallIconComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <NoInstallIcon/>
      <S.CardTitle>{t("iconsFeatures.calendarOne")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(NoInstallIconComponent)