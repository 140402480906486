import React from "react"
import * as S from "./styled"

import RealTimeDataIcon from "../../icons/realTimeDataIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const RealTimeDataComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <RealTimeDataIcon/>
      <S.CardTitle>{t("iconsFeatures.medicalOne")}<br/>{t("iconsFeatures.medicalTwo")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(RealTimeDataComponent)