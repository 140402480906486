import React from "react"
import * as S from "./styled"
import IntroImg from "../IntroImg"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { withTrans } from "../../i18n/withTrans"

import loadable from "@loadable/component"

// import Navigation from "../Navigation"
const Navigation = loadable(() => import("../Navigation"))

const Intro = ({ t, i18n }) => (
  <S.BgColor>
    {/* <BackgroundSection /> */}
    <S.Bg>
      <S.WrapperHeader>
        <Navigation />
      </S.WrapperHeader>
    </S.Bg>
    <S.WrapperIntro id="intro" className="container">
      <S.WrapperInfo>
        <S.Title>
          {t("introSection.titleOne")}
          <br />
          {/* {t("introSection.titleTwo")} */}
        </S.Title>
        <S.Subtitle>
          {t("introSection.subtitleOne")} <br />
          <br /> {/* {t("introSection.subtitleTwo")} */}
        </S.Subtitle>
        <S.WrapperBtnIntro>
          <AniLink
            to="https://www.kalendme.com/en/federico-plymb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WrapperTriggers>
              <S.Button>{t("introSection.buttonRequest")}</S.Button>
              <S.ButtonConsult>
                {t("introSection.buttonConsultor")}
              </S.ButtonConsult>
            </S.WrapperTriggers>
          </AniLink>
          <S.ButtonLogin
            href="https://portal.pyneapp.com/login?origin=site"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </S.ButtonLogin>
        </S.WrapperBtnIntro>
        <S.ImageInfo />
        <S.WrapperBtnIntroLogos>
          <a
            href="https://play.google.com/store/apps/details?id=com.pyne.portal"
            target="_blank"
            rel="noopener noreferrer"
            className="google"
          >
            {" "}
          </a>
          <a
            href="https://apps.apple.com/us/app/pyne-app/id1539983536?ign-mpt=uo%3D2"
            target="_blank"
            rel="noopener noreferrer"
            className="apple"
          >
            {" "}
          </a>
        </S.WrapperBtnIntroLogos>
      </S.WrapperInfo>
      <S.WrapperImg>
        <IntroImg />
      </S.WrapperImg>
    </S.WrapperIntro>
  </S.BgColor>
)

export default withTrans(Intro)
