import React from "react"
import * as S from "./styled"

import RemoteIcon from "../../icons/remoteWorkIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const RemoteIconComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <RemoteIcon/>
      <S.CardTitle>{t("iconsFeatures.worldOne")}<br/>{t("iconsFeatures.worldTwo")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(RemoteIconComponent)