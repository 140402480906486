import styled from "styled-components"
import media from "styled-media-query";
import backgroundThumb from "../../images/thumbVideo.png"

export const Title = styled.h2`
  font-size: 60px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  line-height: 70px;
  text-align: right;
  opacity: 0.5;

  ${media.lessThan("small")`
    font-size: 35px;
  `}

  ${media.between("medium", "large")`
    font-size: 40px;
    text-align: center;
  `}
`

export const TitleGreen = styled.h2`
  font-size: 60px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  line-height: 70px;
  text-align: right;
  opacity: 0.5;
  color: ${props => props.theme.colors.success};
  margin-left: 15px;

  ${media.lessThan("small")`
    font-size: 35px;
    margin-left: 0;
  `}
  ${media.between("medium", "large")`
    font-size: 40px;
  `}
`

export const TitleGrey = styled.h2`
  font-size: 60px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  line-height: 70px;
  text-align: right;
  opacity: 0.5;
  margin-left: 70px;

  ${media.lessThan("small")`
    font-size: 35px;
    margin-left: 0;
    line-height: 30px;
  `}

  ${media.between("medium", "large")`
    font-size: 40px;
  `}

`

export const AsideTitle = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-size: 25px;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.subtitle};
    font-weight: 600;
    line-height: 40px;
  }
  > svg {
    margin-left: 30px;
    margin-top: -50px;
    }
    ${media.lessThan("small")`
      margin-top: 40px;
      align-items: flex-end;
    > svg {
      width: 40px;
    }
  `}
`

export const Content = styled.p`
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 400;
  line-height: 30px;
  ${media.lessThan("small")`
    > br {
      display: none;
    }
    margin-top: 15px;
    line-height: 30px;
  `}
  ${media.between("medium", "large")`
    margin-top: 30px;
    line-height: 40px;
    font-size: 20px;
    > br {
      display: none;
    }
  `}
`

export const WrapperFeature = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 200px;
  grid-template-areas: "infoTitle infoGeneral";

  ${media.lessThan("small")`
    display: block;
  `}

  ${media.between("medium", "large")`
    display: block;
  `}

`

export const WrapperVideo = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 400px;
  grid-template-areas: "infoTitle infoGeneral";

  ${media.lessThan("small")`
    display: block;
    margin: 50px 30px;
    > .title-video {
      justify-content: center;
    }
  `}

  ${media.between("medium", "large")`
    display: block;
    margin: 0 40px;
  `}
`

export const ThumbText = styled.div`
  grid-area: infoGeneral;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  h5 {
    font-family: "Josefin Sans",sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: ${props => props.theme.colors.subtitle};
    margin-left: 50px;
    line-height: 55px;
    ${media.lessThan("small")`
      font-size: 20px;
      margin: 30px 10px 20px 10px;
      line-height: 35px;
      text-align: center;

    `}
    ${media.between("medium", "large")`
      padding: 40px 0px;
      margin-left: 0px;
    `}
  }
  p {
    font-family: "Josefin Sans",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #707070;
    line-height: 40px;
    margin-left: 50px;
    ${media.lessThan("small")`
      font-size: 15px;
      margin: 0px 10px 0px 10px;
      line-height: 25px;
      text-align: center;
    `}
    ${media.between("medium", "large")`
      //padding: 50px 0px;
      margin-left: 0px;
    `}
  }
`

export const IframeVideo = styled.div`
  margin: 25px 0;
`

export const Thumb = styled.div`
  background-image: url(${backgroundThumb});
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: infoTitle;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: center;
  }
  > h5 {
    font-size: 40px;
    font-family: "Josefin Sans",sans-serif;
    color: #fff;
    font-weight: 700;
    margin-left: 25px;
    ${media.lessThan("small")`
    font-size: 25px;
    margin: 20px 10px;
    text-align: center;
    `}
    ${media.between("medium", "large")`
      padding: 50px 0px;
    `}
  }
  > h6 {
    font-size: 40px;
    font-family: "Josefin Sans",sans-serif;
    color: #fff;
    font-weight: 700;
    text-align: right;
    margin-right: 25px;
    ${media.lessThan("small")`
    font-size: 25px;
    margin: 20px 10px;
    `}
    ${media.between("medium", "large")`
      padding: 50px 0px;
    `}
  }
`

export const TitleMediumScreen = styled.h3`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 90px;
  margin-top: 10px;
  opacity: 0.5;
  > span {
    color: ${props => props.theme.colors.success}; 
  }
  ${media.lessThan("small")`
    display: none;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
`

export const WrapperTitle = styled.div`
  grid-area: infoTitle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${media.between("medium", "large")`
    margin-bottom: 100px;
    display: none;
  `}
`
export const WrapperGeneral = styled.div`
  grid-area: infoGeneral;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
export const WrapperOtherFeatures = styled.div`
  display: flex;
  justify-content: center;
`
export const TitleOther = styled.h3`
  margin-top: ${(props) => (props.new ? '0' : '30px')};
  margin-bottom: ${(props) => (props.new ? '0' : '0')};
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  > hr {
    width: 250px;
    border: 0;
    height: 0;
    box-shadow: 0 0 5px 1px ${props => props.theme.colors.success};
    opacity: 0.7;
    margin-top: 1px;
  }
  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 30px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    > hr {
    width:220px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`
export const WrapperCarousel = styled.div`
  margin-top: 70px;
  padding: 20px 0;
  display: grid;
  grid-gap: 20px 0;
  grid-template-columns: 20px 1fr 20px;
  align-content: start;
  display: none;
  > * {
    grid-column: 2 / -2;
  }
  > div {
    grid-column: 1 / -1;
    overflow-y: hidden;
    margin-bottom: calc(-0.1 * 20px);
  }
  ${media.lessThan("small")`
    display: grid;
    margin-top: 30px;
    padding-bottom: 0;
    margin: 0 30px;
  `}
  ${media.between("medium", "large")`
    margin-top: 0;
    `}
`

export const ListWrapper = styled.ul`
  display: grid;
  /* grid-gap: calc(20px / 2); */
  grid-gap: 50px;
  /* grid-template-columns: 10px repeat(6, calc(50% - 20px * 2)) 10px; */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: minmax(230px, 1fr);
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * 20px);
  margin-bottom: calc(-0.5 * 20px);
  &:before,
  &:after {
    content: "";
  }
`

export const ListItem = styled.li`
  scroll-snap-align: center;
  padding: calc(20px / 2 * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};
  border-radius: 20px;
  width: 226px;
  height: 226px;
  box-shadow: 0px 20px 20px #7682b72e;
`
export const WrapperBackground = styled.div`
  margin-bottom: 100px;

  .imgM {
    width: 500px;
    ${media.lessThan("small")`
      width: 300px;
  `}
  }

  ${media.lessThan("small")`
    margin: 0px;
    background-image: none;
  `}

  ${media.between("medium", "large")`
    margin-bottom: 30px;
    background-image: none;
    `}

`

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  @media(max-width: 800px) {
    top: 10%;
    left: 20%;
  }
`


export const Arrow = styled.div`
    display: flex;
    margin-top: 50px;
    ${media.lessThan("small")`
      margin-top: 10px;
    `}
    &:after {
      content: "⟶";
      position: absolute;
      width: 35px; height: 35px;
      margin: 0 auto;
      left: 0; right: 0;
      font-size: 35px;
      animation: arrow-pulse-down 900ms linear infinite;
      position: relative;
      top: -50px;
    }

    @keyframes arrow-pulse-down {
		0% {
			left: 40px;
			opacity: 0;
		}
		25% {
			opacity: .25;
		}
		50% {
			opacity: .5;
		}
		75% {
			opacity: .75;
		}
		100% {
			left: 20px;
			opacity: 1;
		}
	}
  `

export const TitleOne = styled.h3`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #7EBA2A;
  //padding-top: 30px;
  ${media.lessThan("small")`
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  `}
`

export const TitleTwo = styled.h3`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #707070;
  ${media.lessThan("small")`
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  `}
`

export const TitleThree = styled.h3`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #707070;
  ${media.lessThan("small")`
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  `}
`

export const TitleVideoOn = styled.h6`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  margin: 20px 10px 0 0;
  color: #707070;
  ${media.lessThan("small")`
    text-align: center;
    justify-content: center;
  `}
`
export const TitleVideo = styled.h6`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  margin: 20px 10px 0 0;
  color: #707070;
  transition: color 0.5s;
  cursor: pointer;
  &:hover {
  color: ${props => props.theme.colors.success};
  }
  ${media.lessThan("small")`
    text-align: center;
    > span {
      display: none;
    }
  `}
`

export const WrapperTitles = styled.div`
  // margin: 80px 0;
  ${media.lessThan("small")`
    width: 100%;
    margin: 20px 0;
  `}
  ${media.between("medium", "large")`
      margin: 40px 40px 0px 40px;
  `}
`

export const WrapperSubtitles = styled.div`
  margin: 80px 0;
  ${media.lessThan("small")`
    margin: 20px 30px;
  `}
  ${media.between("medium", "large")`
      margin: 50px;
  `}
`

export const SubtitleOne = styled.h4`
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
  color: #4A4A4A;
  ${media.lessThan("small")`
    text-align: center;
  `}
`

export const SubtitleContent = styled.p`
  font-family: 'Josefin Sans';
  font-size: 25px;
  line-height: 30px;
  color: #4A4A4A;
  margin-top: 30px;
  font-weight: 300;
  ${media.lessThan("small")`
    text-align: center;
    font-size: 16px;
  `}
`

export const WrapperSubIcon = styled.div`
/*   display: flex;
  align-items: baseline; */
  > svg {
    position: absolute;
    top: 70px;
    right: 300px;
  }
  ${media.lessThan("small")`
    svg {
      position: absolute;
      top: 185px;
      right: 25px;
      width: 30px;
      ${media.lessThan("small")`
/*         top: 240px;
        right: 20px; */
        top: 200px!important;
        right: 25px!important;
      `}
    }
  `}
  ${media.between("medium", "large")`
    svg {
      top: 340px;
      right: 380px;
    }
  `}
  ${media.lessThan("1380px")`
    svg {
      position: absolute;
      top: 70px;
      right: 240px;
    }
  `}

`

export const WrapperNoCarrousel = styled.div`
  margin-top: 80px;
  ${media.lessThan("small")`
    display: none;
  `}
  ${media.between("medium", "large")`
    margin: 50px;
  `}
`

export const Partners = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
  .fit-image {
    height: fit-content;
    padding: 20px 0;
  }
  ${media.lessThan("small")`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  `}
  
`

export const TitleNewSection = styled.h5`
  font-size: 25px;
  font-weight: bold;
  color: #027ad1;
  margin-bottom: 20px;
  line-height: 35px;
  ${media.lessThan("small")`
      margin: 0 40px;
  `}
`

export const SubTitleNewSection = styled.p`
  font-family: "Josefin Sans",sans-serif;
  font-size: 18px;
  font-weight: 200;
  color: #4A4A4A;
  line-height: 25px;
  ${media.lessThan("small")`
      margin: 20px 40px ;
      margin-top: ${(props) => (props.other ? '0' : '20px')};
      margin-bottom: ${(props) => (props.other ? '0' : '0')};
  `}
`
