import styled from "styled-components"
import media from "styled-media-query"
import backgroundPattern from "../../images/background_mobile.png"
import backgroundImage from "../../images/introInfo.png"
import badgeGoogle from "../../images/google-play.png"
import badgeApple from "../../images/apple-store.png"

export const BgColor = styled.section`
  background-color: ${props => props.theme.colors.primary};
`

export const WrapperHeader = styled.div`
  ${media.lessThan("small")`
  display: none;
  `}
`

export const Title = styled.h1`
  font-size: 50px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  line-height: 55px;
  color: ${props => props.theme.colors.white};
  padding-top: 50px;
  ${media.lessThan("small")`
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    padding-top: 0px;
  `}
  ${media.between("medium", "large")`
    padding-top: 0px;
  `}
`

export const Subtitle = styled.p`
  margin-top: 20px;
  font-size: 25px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.white};
  font-weight: 300;
  line-height: 30px;
  ${media.lessThan("small")`
    text-align: center;
    font-size: 16px;
    line-height: 33px;
    > br {
      display: none;
    }
  `}
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 190px;
  height: 44px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ed7878;
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: #7ad1a6;
  }
  ${media.lessThan("small")`
      width: 150px;
  `}
`

export const ButtonConsult = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 215px;
  height: 44px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #48c774;
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;
  margin-left: 10px;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: #7ad1a6;
  }
  ${media.lessThan("small")`
      width: 150px;
  `}
`
export const ButtonLogin = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 190px;
  height: 44px;
  background-color: rgba(83, 158, 60, 0.2);
  border-radius: 26px;
  color: ${props => props.theme.colors.footer};
  background-color: ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.success};
  }
  display: none;
  ${media.lessThan("small")`
      display: flex;
      width: 150px;
      color: ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.white};
      display: none;
  `}
`

export const WrapperBtnIntro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
  ${media.lessThan("small")`
    margin-bottom: 0px;
  `}
  ${media.between("medium", "large")`
    margin-bottom: 30px;
  `}
`

export const ImageInfo = styled.div`
  background-image: url(${backgroundImage});
  height: 320px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-bottom: 10px;
  display: none;
  ${media.lessThan("small")`
    display: block;
  `}
`

export const WrapperIntro = styled.section`
  margin-top: 50px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 500px;
  grid-template-areas: "info image";

  ${media.lessThan("small")`
    margin-top: 0px;
    display: block;
    margin-bottom: 20px;
  `}

  ${media.between("medium", "large")`
    display: block;
    margin-bottom: 50px;
  `}
`

export const WrapperBtnIntroLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .google {
    width: 170px;
    height: 70px;
    background-image: url(${badgeGoogle});
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    ${media.lessThan("small")`
      display: block;
    `}
  }
  & .apple {
    width: 170px;
    height: 70px;
    background-image: url(${badgeApple});
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    ${media.lessThan("small")`
      display: block;
    `}
  }
`

export const WrapperBackgroundMobile = styled.div`
  display: none;
  ${media.lessThan("small")`
    display: block;
    background-image: url(${backgroundPattern});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: -106px;
    background-position-x: 134px;
    height: 365px;
    position: relative;
    position: absolute;
    width: 342px;
  `}
`

export const WrapperInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.lessThan("small")`
    margin-bottom: 0;
    margin: 0 30px;
  `}
  ${media.between("medium", "large")`
    margin: 0 40px;
  `}
`
export const WrapperImg = styled.div`
  grid-area: image;
  margin: 0 auto;

  ${media.lessThan("small")`
    display: none;
  `}

  ${media.between("medium", "large")`
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  `}
`
export const Bg = styled.div`
  /* background-color: ${props => props.theme.colors.primary}; */
  height: 120px;
`

export const WrapperTriggers = styled.div`
  display: flex;
`
