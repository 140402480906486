import React from "react"
import * as S from "./styled"

import WorkSpaceIcon from "../../icons/workSpaceIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const WorkSpaceIconComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <WorkSpaceIcon/>
      <S.CardTitle>{t("iconsFeatures.exelOne")}<br/>{t("iconsFeatures.exelTwo")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(WorkSpaceIconComponent)