import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

const IntroImg = () => {
  const { introInfo } = useStaticQuery(
    graphql`
      query {
        introInfo: file(relativePath: { eq: "mainImage.png"}) {
          childImageSharp {
            fluid(maxWidth: 485) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return <Img fluid={introInfo.childImageSharp.fluid} style={{ width: '485px'}} alt="Introduction"/>
}

export default IntroImg