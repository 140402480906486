import React from "react"
import * as S from "./styled"

import GlobalIcon from "../../icons/globalIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const GlobalIconComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <GlobalIcon/>
      <S.CardTitle>{t("iconsFeatures.scheduleOne")}<br/>{t("iconsFeatures.scheduleTwo")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(GlobalIconComponent)