import styled from "styled-components"
import media from "styled-media-query"

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`
export const CardTitle = styled.p`
  margin-top: 30px;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #fff;
  ${media.lessThan("small")`
    font-size: 20px;
  `}
  ${media.between("medium", "large")`
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
    `}
`
