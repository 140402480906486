import React from "react"
import * as S from "./styled"

import FlexibleShedule from "../../icons/flexibleScheduleIcon.svg"
import { withTrans } from "../../i18n/withTrans"

const FlexibleScheduleIconComponent = ({ t, i18n }) => (
  <>
    <S.Card>
      <FlexibleShedule/>
      <S.CardTitle>{t("iconsFeatures.download")}</S.CardTitle>
    </S.Card>
  </>
)

export default withTrans(FlexibleScheduleIconComponent)