import React from "react"
import * as S from "./styled"

import RemoteWork from "../IconsComponents/remoteWorkIcon"
import FlexibleShedule from "../IconsComponents/flexibleScheduleIcon"
import RealTimeDataIcon from "../IconsComponents/realTimeDataIcon"
import WorkSpaceIcon from "../IconsComponents/workSpaceIcon"
import GlobalIcon from "../IconsComponents/globalIcon"
import NoInstallIcon from "../IconsComponents/noInstall"
import IdeaIcon from "../../icons/ideaIcon.svg"
import { withTrans } from "../../i18n/withTrans"
import PlayerVideo from "../../icons/playerVideo.svg"
import managementImg from "../../images/management-image.png"
import controlImg from "../../images/control-image.png"
import comunicationImg from "../../images/comunication-image.png"
import allImg from "../../images/all.png"

import partnerOne from "../../images/smarkets.png"
import partnerTwo from "../../images/bodyHealth.png"
import partnerThree from "../../images/creapeAway.png"
import partnerFour from "../../images/sorrisoBistro.png"

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Features = ({ t, i18n }) => {

const [open, setOpen] = React.useState(false);
const [openPT, setOpenPT] = React.useState(false);

const onOpenModal = () => setOpen(true);
const onCloseModal = () => setOpen(false);

const onOpenModalPT = () => setOpenPT(true);
const onCloseModalPT = () => setOpenPT(false);

const idTextPT = t("features.titleVideoOne");
const idTextPTCheck = "NÓS";

return (
  <>
    <S.WrapperBackground>
    <div className="container mb-6">
      <S.WrapperOtherFeatures>
        <S.TitleOther new>
          {t("features.titleOtherNewMain")}
        </S.TitleOther>
      </S.WrapperOtherFeatures>
      {/* Management */}
      <div className="columns mt-6 mb-6">
        <div className="column is-flex is-flex-direction-column is-justify-content-center">
          <S.TitleNewSection>
            {t("features.TitleNewSectionOne")}
          </S.TitleNewSection>
          <S.SubTitleNewSection>
            {t("features.SubTitleNewSectionOne")}<br/>
            {t("features.SubTitleNewSectionOneMoreOne")}<br/>
            {t("features.SubTitleNewSectionOneMoreTwo")}
          </S.SubTitleNewSection>
          <br/>
          <S.SubTitleNewSection other>
            {t("features.SubTitleNewSectionTwo")}<br/>
            {t("features.SubTitleNewSectionTwoMore")}
          </S.SubTitleNewSection>
        </div>
        <div className="column is-flex is-justify-content-center">
          <img src={managementImg}className="imgM"/>
        </div>
      </div> 
      {/* Control */}
      <div className="columns mt-6 mb-3">
        <div className="column is-flex is-justify-content-center">
          <img src={controlImg}className="imgM"/>
        </div>
        <div className="column is-flex is-flex-direction-column is-justify-content-center">
          <S.TitleNewSection>
            {t("features.TitleNewSectionTwo")}
          </S.TitleNewSection>
          <S.SubTitleNewSection>
            {t("features.SubTitleNewSectionThree")}
          </S.SubTitleNewSection>
          <br/>
          <S.SubTitleNewSection>
            {t("features.SubTitleNewSectionFour")}
          </S.SubTitleNewSection>
        </div>
      </div> 

      <div className="columns mt-3 mb-6">
        <div className="column is-flex is-flex-direction-column is-justify-content-center">
          <S.TitleNewSection>
            {t("features.TitleNewSectionThree")}
          </S.TitleNewSection>
          <S.SubTitleNewSection>
            {t("features.SubTitleNewSectionFive")}
          </S.SubTitleNewSection>
          <br/>
          <S.SubTitleNewSection>
            {t("features.SubTitleNewSectionSix")}
          </S.SubTitleNewSection>
        </div>
        <div className="column is-flex is-justify-content-center">
          <img src={comunicationImg}className="imgM"/>
        </div>
      </div> 

      <div className="columns mt-6 mb-6">
        <div className="column is-flex is-justify-content-center">
          <img src={allImg}className="imgM"/>
        </div>
        <div className="column is-flex is-flex-direction-column is-justify-content-center">
          <S.TitleNewSection>
            {t("features.TitleNewSectionFour")}
          </S.TitleNewSection>
          <br/>
          <S.SubTitleNewSection>
            • {t("features.SubTitleNewSectionPone")}<br/><br/>
            • {t("features.SubTitleNewSectionPTwo")}<br/><br/>
            • {t("features.SubTitleNewSectionPThree")}<br/><br/>
            • {t("features.SubTitleNewSectionPFour")}<br/><br/>
            • {t("features.SubTitleNewSectionPFive")}<br/><br/>
            • {t("features.SubTitleNewSectionPSix")}<br/><br/>
          </S.SubTitleNewSection>
        </div>
      </div> 
      
      <S.WrapperVideo>
        <S.Thumb onClick={onOpenModal}>
          <h5>{t("features.titleVideoOne")}</h5>
          <div>
            <PlayerVideo/>
          </div>
          <h6>{t("features.titleVideoTwo")}</h6>
        </S.Thumb>
        <S.ThumbText>
          <h5>{t("features.bloquoteTitleOne")} <br/>{t("features.bloquoteTitleTwo")} <br/> {t("features.bloquoteTitleThree")} <br/> {t("features.bloquoteTitleFour")} </h5>
          <p>{t("features.bloquoteParagraphOne")} <br/>
            {t("features.bloquoteParagraphTwo")}
          </p>
        </S.ThumbText>
        {/* Default Version - English  */}
        <Modal open={open} onClose={onCloseModal} center>
        {
          idTextPT.indexOf(idTextPTCheck) !== -1 && idTextPT.indexOf(idTextPTCheck) !== -1 ? (
            <S.IframeVideo>
              <iframe
              title="Prazer, somos a Pyneapp!"
              width="640" 
              height="385" 
              src="https://www.youtube.com/embed/-RdXFGnfcME" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen="allowFullScreen"
              ></iframe>
            </S.IframeVideo>
          )
            : (
            <S.IframeVideo>
              <iframe
              title="We are Pyne! Nice to meet you"
              width="640" 
              height="385" 
              src="https://www.youtube.com/embed/tzLAq9QZdCA" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen="allowFullScreen"
              ></iframe>
            </S.IframeVideo>
          )
        }
        </Modal>
        {/* Default Version - Portuguese  */}
        <Modal open={openPT} onClose={onCloseModalPT} center>
          <S.IframeVideo>
            <iframe
            title="Prazer, somos a Pyneapp!"
            width="640" 
            height="385" 
            src="https://www.youtube.com/embed/-RdXFGnfcME" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen="allowFullScreen"
            ></iframe>
          </S.IframeVideo>
        </Modal>
        <div className="is-flex title-video">
          {/* <S.TitleVideoOn>{t("features.videoOn")}</S.TitleVideoOn> */}
          {/* <S.TitleVideo onClick={onOpenModalPT}>{t("features.videoPT")}</S.TitleVideo> */}
{/*           <S.TitleVideo onClick={onOpenModalPT}>{t("features.videoES")}</S.TitleVideo> */}
        </div>
      </S.WrapperVideo>
    </div>

{/*     <div className="container mt-5">
      <div className="columns">
        <div className="column is-flex is-align-items-center">
          <S.WrapperTitles>
            <S.TitleOne>{t("features.titleOne")}</S.TitleOne>
            <S.TitleTwo>{t("features.titleTwo")}</S.TitleTwo>
            <S.TitleThree>{t("features.titleThree")}</S.TitleThree>
          </S.WrapperTitles>
        </div>
        <div className="column">
          <S.WrapperSubtitles>
            <S.WrapperSubIcon>
              <S.SubtitleOne>{t("features.subtitle")}</S.SubtitleOne>
              <IdeaIcon/>
            </S.WrapperSubIcon>
            <S.SubtitleContent>{t("features.descriptionOne")}</S.SubtitleContent>
            <br/>
            <S.SubtitleContent>{t("features.descriptionOneEX")}</S.SubtitleContent>
          </S.WrapperSubtitles>
        </div>
      </div>
    </div> */}
    <S.WrapperOtherFeatures id="features" className="mt-6">
        <S.TitleOther>
          {t("features.titleOther")}
        </S.TitleOther>
      </S.WrapperOtherFeatures>

      <S.WrapperNoCarrousel className="container">
        <div className="columns is-flex is-justify-content-center mt-5">
          <div className="column is-one-fifth">
            <S.ListItem color="#93C5BC">
              <RemoteWork />
            </S.ListItem>
           </div>
          <div className="column is-one-fifth">
            <S.ListItem color="#E3B038">
              <GlobalIcon />
            </S.ListItem>
          </div>
          <div className="column is-one-fifth">
            <S.ListItem color="#F49982">
              <RealTimeDataIcon />
            </S.ListItem>
          </div>
        </div>

        <div className="columns is-flex is-justify-content-center mt-5">
          <div className="column is-one-fifth">
            <S.ListItem color="#3B4C5B">
              <WorkSpaceIcon />
            </S.ListItem>
            </div>
            <div className="column is-one-fifth">
              <S.ListItem color="#FC5830">
                <FlexibleShedule/>
              </S.ListItem>
            </div>
            <div className="column is-one-fifth">
              <S.ListItem color="#1CA591">
                <NoInstallIcon />
              </S.ListItem>
            </div>
        </div>
      </S.WrapperNoCarrousel>  
    {/* Para Mobile"  */}
     <S.WrapperCarousel>
        <div>
          <S.ListWrapper>
            <S.ListItem color="#9CC2BB">
              <RemoteWork />
            </S.ListItem>
            <S.ListItem color="#ED6B44">
              <FlexibleShedule/>
            </S.ListItem>
            <S.ListItem color="#D5CD9B">
              <RealTimeDataIcon />
            </S.ListItem>
            <S.ListItem color="#3D4C5A">
              <WorkSpaceIcon />
            </S.ListItem>
            <S.ListItem color="#DEAF47">
              <GlobalIcon />
            </S.ListItem>
            <S.ListItem color="#72B995">
              <NoInstallIcon />
            </S.ListItem>
          </S.ListWrapper>
          <S.Arrow></S.Arrow>
        </div>
      </S.WrapperCarousel> 

    </S.WrapperBackground>

    <S.WrapperOtherFeatures>
        <S.TitleOther>
          {t("features.titleOtherNew")}
        </S.TitleOther>
      </S.WrapperOtherFeatures>
      <div className="container">
        <S.Partners>
          <img src={partnerOne} className="fit-image"/>
          <img src={partnerTwo} className="fit-image"/>
          <img src={partnerThree} className="fit-image"/>
          <img src={partnerFour} className="fit-image"/>
        </S.Partners>
      </div>
  </>
)}
export default withTrans(Features)